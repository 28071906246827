import React from "react";
import heroMedia from '../../assets/images/hero-media.png';
import fb from '../../assets/images/fb.png';
import twitter from '../../assets/images/twitter.png';
import telegram from '../../assets/images/telegram.png';
import discord from '../../assets/images/discord.png';
import instagram from '../../assets/images/instagram.png';
import logo1 from '../../assets/images/logo1.png';

const Footer = () => {
    return <>
        <footer>
            <div className="container">
                <div className="text-center">
                    <img className="footer_logo" src={logo1} alt="" />
                </div>
                <h2 className="text-center">JOIN OUR COMMUNITY</h2>
                <div className="social_links">
                    {/* <a href=""><img src={fb}></img></a> */}
                    {/* <a href=""><img src={discord}></img></a> */}
                    <a href="https://twitter.com/Doge_Pad" target="_blank"><img src={twitter}></img></a>
                    <a href="https://t.me/dogepad_dog" target="_blank"><img src={telegram}></img></a>
                    <a href="https://t.me/DogePadAnn" target="_blank"><img src={telegram}></img></a>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="menu_wrap">
                            <a href="/" className="menu_link home">
                                <span className="text">HOME</span>
                                <span className="ball"></span>
                            </a>
                            <a href="https://bridge.dogechain.dog/" className="menu_link explorer">
                                <span className="text">Dogechain<br />Bridge</span>
                                <span className="ball"></span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center">
                        <img src={heroMedia} className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-4">
                        <div className="menu_wrap menu_wrap2">
                            <a href="/contact-us" className="menu_link contact">
                                <span className="text">Contact us to<br /> Launch on Dogepad</span>
                                <span className="ball"></span>
                            </a>
                            <a href="/faq" className="menu_link faq">
                                <span className="text">FAQ</span>
                                <span className="ball"></span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer_bottom row">
                    <div className="col-lg-6 leftf">
                        <p>©2022 - DogePad, Inc.</p>
                    </div>
                    <div className="col-lg-6 rightf">
                        <ul>
                            <li><a href="">Terms &amp; Conditions</a></li> |
                            <li><a href="">Privacy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

    </>
}

export default Footer;