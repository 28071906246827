import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AOS from 'aos';
const ComingSoon = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="coming_soon">
            <h1>Coming Soon</h1>
            <p>We are working on it</p>
        </div>
    </>)
}
export default ComingSoon;