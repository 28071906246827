import React from "react";
import logo from '../../assets/images/logo.png';
import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Tab,
    Tabs,
    Accordion,
    Modal,
    Form,
    Nav,
    Navbar
} from 'react-bootstrap'
import { Link, NavLink } from "react-router-dom";

const Header = (props) => {
    const [unlockLPModalShow, setUnlockLPModalShow] = React.useState(false);
    const [manageCampaignModalShow, setManageCampaignModalShow] = React.useState(false);

    return <>
        <header>
            <Navbar
                // fixed="top"
                expand="lg"
                collapseOnSelect
            >
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img src={logo} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar" />
                    <Navbar.Collapse id="basic-navbar">
                        <Nav className="ms-auto">
                            {/* <NavLink className="nav-link" to="/all-campaigns">All Campaigns</NavLink>
                            <NavLink className="nav-link" to="/create-campaign">Create Campaign</NavLink>
                            <NavLink className="nav-link" to="/" onClick={() => setManageCampaignModalShow(true)}>Manage Campaign</NavLink>
                            <NavLink className="nav-link" to="/" onClick={() => setUnlockLPModalShow(true)}>Unlock LP</NavLink>
                            <NavLink className="nav-link" to="/pool">Pool</NavLink> */}
                            <a href="/coming-soon" className="btn_white">Launch&nbsp;App</a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>

        {/* UNLOCK LP MODAL */}

        <Modal className="custom_modal" show={unlockLPModalShow}
            onHide={() => setUnlockLPModalShow(false)}
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Manage LP LOckup Contract</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form_group">
                    <label>Contract Address</label>
                    <Form.Control type="text" className="form_control" />
                    {/* <div className="error_custom">Error message</div> */}
                </div>
                <div className="form_group">
                    <label>Token Address</label>
                    <Form.Control type="text" className="form_control" />
                    {/* <div className="error_custom">Error message</div> */}
                </div>
                <div className="btn_wrap text-center">
                    <Button type="submit" className="btn_black">UNLOCK LP TOKENS</Button>
                </div>
            </Modal.Body>
        </Modal>

        {/* MANAGE CAMPAIGN MODAL */}
        <Modal className="custom_modal" show={manageCampaignModalShow}
            onHide={() => setManageCampaignModalShow(false)}
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Manage your Campaign</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form_group">
                    <label>Campaign Address</label>
                    <Form.Control type="text" className="form_control" />
                    {/* <div className="error_custom">Error message</div> */}
                </div>
                <p className="font_silkscreen">MANAGE</p>
                <div className="row row_1">
                    <div className="col-lg-5">
                        <p className="mb-0">Withdraw your assets from the campaign</p>
                    </div>
                    <div className="col-lg-7 btn_wrap">
                        <Button type="submit" className="btn_black mt-0">Withdraw Assets</Button>
                    </div>
                </div>
                <div className="row row_1">
                    <div className="col-lg-5">
                        <p className="mb-0">Add Liquidity to the token pool</p>
                    </div>
                    <div className="col-lg-7 btn_wrap">
                        <Button type="submit" className="btn_black mt-0">ADD Liquidity</Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    </>
}

export default Header;