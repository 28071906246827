import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Tab,
    Tabs,
    Accordion,
    Modal,
    Form,
    Nav,
} from 'react-bootstrap';
import AOS from 'aos';
import heroMedia from '../assets/images/hero-media.png';

const PoolPage = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="pool_page">
            <Container>
                <Row>
                    <div className="col-lg-12">
                        <div className="breadcrumb">
                            <a href="/">Dashboard</a>
                            <a href="" className="active">Pool</a>
                        </div>
                        <div className="text-center">
                            <h1>DogePad-Doge Pool</h1>
                            <p className="font_silkscreen">Deposite DogePad-Doge FLP, Earn Doge</p>
                        </div>
                        <div className="pinkbox_round">
                            <div className="row row_1">
                                <div className="col-md-2 icon"><img src={heroMedia} alt="" /></div>
                                <div className="col-md-8">
                                    <div className="inner_card">
                                        <p>Your DogePad-Doge FLP Balance</p>
                                        <h3>0.00</h3>
                                    </div>
                                </div>
                                <div className="col-md-2 btn_wrap">
                                    <button className="btn_black">Aprpove</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>

    </>)
}
export default PoolPage;