import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import heroMedia from '../assets/images/hero-media.png';
import t1 from '../assets/images/t1.png';
import t2 from '../assets/images/t2.png';
import t3 from '../assets/images/t3.png';
import t4 from '../assets/images/t4.png';
import s1 from '../assets/images/s1.png';
import s2 from '../assets/images/s2.png';
import dogFaceRound from '../assets/images/dog-face-round.svg';
// import dogFaceRoundOnly from '../assets/images/dog-face-only.svg';
// import dogSpecs from '../assets/images/specs.svg';
import { motion } from 'framer-motion';
// import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
// import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import AOS from 'aos';

const power4EaseOut = [.09, 0.3, .06, 1.05];

let fadeVariants = {
    initial: {
        opacity: 0,
        y: "10%",
    },
    animate: {
        opacity: 1,
        y: "0%",
        transition: {
            duration: 0.6,
            ease: "circOut",
        }
    }
};

let revealVariants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.4,
            ease: "circOut",
        }
    }
};

let scaleVariants = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: power4EaseOut,
        }
    }
};

const HomePage = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="homepage">
            <div className="hero_banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 hero_content_wrap">
                            <div className="hero_content">
                                <p className="p1">FINALLY - LET'S RAISE THE WOOF!</p>
                                <p className="p2">Dogechain's safest launchpad</p>
                                <h1>DOGEPAD - LIQUIDITY LOCKING, STAKING REWARDS AND MOON LAUNCHES!</h1>
                                <p className="p3">Built by DOGE fans for Doge fans.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 hero_media_wrap">
                            <div className="hero_media">
                                <img src={heroMedia} alt="" className="img-fluid" />
                                {/* <img src={dogFaceRoundOnly} alt="" className="img-fluid" />
                                <img src={dogSpecs} alt="" className="dog_spces img-fluid" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="trust_section">
                <div className="container">
                    <h2 className="text-center">$DPAD’s MULTIPLE UTILITIES</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="trust_block">
                                <div className="icon">
                                    <img src={t1} alt="" />
                                </div>
                                <h4>HODL for Sale Access</h4>
                                <p>Holders of more than 3000 $DPAD will have exclusive access to token sales on the platform for the first 2 hours from listing.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trust_block">
                                <div className="icon">
                                    <img src={t2} alt="" />
                                </div>
                                <h4>Buy to Launch</h4>
                                <p>Project owners will have to purchase a particular level of $DPAD in order to list their project on the sales platform. This value will be a 1% of the USD equivalent of $DPAD to ensure that the holding required won't become unreasonable as the price of $DPAD appreciates.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trust_block">
                                <div className="icon">
                                    <img src={t3} alt="" />
                                </div>
                                <h4>Earn Rewards in $WDOGE</h4>
                                <p>Token sales' fees in WDOGE earned through the platform will be available to earn through our liquidity reward staking pool. Stake $DPAD/WDOGE LP tokens and earn WDOGE!</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="trust_block">
                                <div className="icon">
                                    <img src={t4} alt="" />
                                </div>
                                <h4>Staking Rewards without Emissions</h4>
                                <p>Dogepad 2% of $WDOGE raised on the token sale platform as long as softcap is met. 60% of this is available through our staking pool for token holders. For example, if a project raised 1,000,000 $WDOGE, Dogepad would earn 20,000 $WDOGE. 12,000 WDOGE would be added into the staking pools to be earned by stakers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="platforms-artwork">
                <div className="wrapper">
                    <div className="tools">
                        <motion.div className="tool dogepad"
                            variants={fadeVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                            <h2>Trust in DogePAD</h2>
                        </motion.div>

                        <motion.div className="tool stellar" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>

                        <motion.div className="tool ethereum" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>

                        <motion.div className="tool hyperledger" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>

                        <motion.div className="tool multichain" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>

                        <motion.div className="tool credits" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>

                        <motion.div className="tool nem" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>

                        {/* <motion.div className="tool corda" title=""
                        variants={scaleVariants}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            amount: 0.2
                        }}
                    >
                        <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                    </motion.div> */}

                        <motion.div className="tool hedera" title=""
                            variants={scaleVariants}
                            initial="initial"
                            whileInView="animate"
                            viewport={{
                                once: true,
                                amount: 0.2
                            }}
                        >
                            <img src={dogFaceRound} alt="" draggable="false" loading="lazy" />
                        </motion.div>


                    </div>

                    <div className="rings">
                        <div className="ring inner-ring">
                            <svg height="100%" width="100%">
                                <circle cx="50%" cy="50%" r="50%" stroke="#798686" strokeDasharray="3,10" strokeWidth="1" fill="none" />
                            </svg>
                        </div>
                        <div className="ring middle-ring">
                            <svg height="100%" width="100%">
                                <circle cx="50%" cy="50%" r="50%" stroke="#798686" strokeDasharray="3,10" strokeWidth="1" fill="none" />
                            </svg>
                        </div>
                        {/* <div className="ring outer-ring">
                        <svg height="100%" width="100%">
                            <circle cx="50%" cy="50%" r="50%" stroke="#DDDDDD" strokeDasharray="3,10" strokeWidth="2" fill="none" />
                        </svg>
                    </div> */}
                    </div>
                </div>
            </div>

            <div className="text-center mt-5">
                <a href="https://t.me/DogePadAnn" target="_blank" className="btn_white">JOIN TELEGRAM FOR UPDATES</a>
            </div>


            {/* <section className="how_do_get_section">
                <div className="container">
                    <h2 className="text-center">Dogechain. How Do I Get?!</h2>
                </div>
                <div className="row">
                    <div className="col-md-4 p-0">
                        <div className="hdg_block">
                            <h4>Doge hodlers get FREE Dogechain!</h4>
                            <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="hdg_block">
                            <h4>buy more dogechain?cheap fees</h4>
                            <p>buy more dogechain?cheap fees</p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="hdg_block">
                            <h4>Doge hodlers get FREE Dogechain!</h4>
                            <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                        </div>
                    </div>
                </div>
            </section> */}

            <div className="container">
                <section className="fees_token_section pinkbox_round">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="left_side">
                                <div className="icon">
                                    <img src={dogFaceRound} alt="" />
                                </div>
                                <div>
                                    <div className="title">SAFE TOKEN SALES ON DOGECHAIN</div>
                                    <p>It can be ruff finding safe token sales in crypto these days, but we want to change things for the paw-sitive! With auto liquidity locking, fee rewards available for liquidity providers and joining us on our Dogechain journey, you'd be barking mad not to join our Presale! Where else but to launch our token sale but on dogepad.dog token sale platform! Presale August - Date to be confirmed!</p>
                                    {/* <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="right_side">
                                <div className="block1">
                                    <h2>1000,000 TOKENS</h2>
                                    <p className="font_silkscreen">TOTAL SUPPLY</p>
                                </div>
                                <div className="block2">
                                    <h4>600,000 TOKENS</h4>
                                    <p className="font_silkscreen">PRESALES TOKENS</p>
                                </div>
                                <div className="block2">
                                    <h4>300,000 TOKENS</h4>
                                    <p className="font_silkscreen">Dogeswap Liquidity</p>
                                </div>
                                <div className="block2">
                                    <h4>75,000 TOKENS</h4>
                                    <p className="font_silkscreen">Team</p>
                                </div>
                                <div className="block2 mb-0">
                                    <h4>25,000 TOKENS</h4>
                                    <p className="font_silkscreen">Marketing </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="social_section_wrap">
                <div className="container">
                    <section className="social_section">
                        <div className="row">
                            <div className="col-md-12 col-lg-4">
                                <div className="social_block">
                                    <div className="title">
                                        <img src={s1} alt="" />
                                    </div>
                                    <h4>much gifts! a reserved token supply for airdropping to Robinhood users</h4>
                                    <p>Although Robinhood is limiting Dogecoin withdrawals right now, once they open up there will be an ample supply of $DC tokens to distributes to Robinhood hodlers.</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="social_block">
                                    <div className="title">
                                        <img src={s2} alt="" />
                                    </div>
                                    <h4>much gifts! a reserved token supply for airdropping to Robinhood users</h4>
                                    <p>Although Robinhood is limiting Dogecoin withdrawals right now, once they open up there will be an ample supply of $DC tokens to distributes to Robinhood hodlers.</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-4">
                                <div className="social_block">
                                    <div className="title">
                                        <img src={s2} alt="" />
                                    </div>
                                    <h4>Dogechain Sanctuary and the 2023 Dogechain Conference</h4>
                                    <p>Although Robinhood is limiting Dogecoin withdrawals right now, once they open up there will be an ample supply of $DC tokens to distributes to Robinhood hodlers.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div> */}

            <div className="custom_tabbing">
                <svg width="2100" height="950" className="round_image">
                    <rect rx="450" stroke="#FFBA47" stroke-width="1" id="dots" stroke-dasharray="5 15" height="950" width="2100" y="0" x="0" fill="none" />
                    <rect rx="450" stroke="#FFBA47" stroke-width="1" id="line" className="line2" height="950" width="2100" y="0" x="0" stroke-dasharray="4027" fill="none" />
                </svg>
                <Tab.Container defaultActiveKey="first">
                    <div className="tab_title">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    <span>1</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">
                                    <span>2</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">
                                    <span>3</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div className="tab_content">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <h4>August 2022</h4>
                                <ul>
                                    {/* <li>Audit Passed <a href="">View Report</a></li> */}
                                    <li>Audit</li>
                                    <li>PreSale on The Dogepad Platform</li>
                                    <li>Listing on&nbsp;<a target="_blank" href="https://www.dogeswap.org">Dogeswap</a></li>
                                    <li>Liquidity Locking</li>
                                    <li>Strategic Marketing Campaign</li>
                                </ul>

                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <h4>Quarter 4 2022</h4>
                                <ul>
                                    <li>First PreSales scheduled on The Dogepad (We have 3 lined up already!)</li>
                                    <li>Opening of Staking pools to earn fees in $WDOGE</li>
                                    <li>Launch of KYC Portal</li>
                                    <li>Audit firm partnerships</li>
                                    <li>Integration with further DEX platforms</li>
                                    <li>Token Bridge</li>
                                </ul>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <h4>Quarter 1 2023</h4>
                                <ul>
                                    <li>Further PreSales</li>
                                    <li>Dutch Auction Sales Portal</li>
                                    <li>Stand Alone Liquidity Lock contracts and App</li>
                                    <li>NFT Marketplace</li>
                                </ul>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>

            <div className="container">
                <section className="presale_section">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="presale_content">
                                <h2>PRE SALE</h2>
                                <p className="mb-4">$DPAD tokens will be available through the token sale at 1 $DPAD=2 $WDOGE</p>
                                <p className="mb-4">
                                    Hardcap 600,000 $DPAD</p>
                                <p className="mb-0">
                                    $DPAD will be listed at a price of 1 $DPAD= 2.2 $WDOGE on Dogeswap.
                                </p>
                                <a href="/coming-soon" className="btn_white">Launch&nbsp;App</a>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="presale_media">
                                <img src={dogFaceRound} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </>)
}
export default HomePage;