import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Tab,
    Tabs,
    Accordion,
    Modal,
    Form,
    Nav,
} from 'react-bootstrap';
import AOS from 'aos';
import heroMedia from '../assets/images/hero-media.png';
import dogFaceRound from '../assets/images/dog-face-round.svg';

const FaqPage = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="pool_page">
            <Container>
                <Row>
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h1>FAQS</h1>
                            <p className="font_silkscreen">HOW CAN WE HELP YOU?</p>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>1. How to bridge funds to DogeChain? </Accordion.Header>
                                <Accordion.Body>
                                    <p>Link to Explanation:<br /> 
                                    <a target="_blank" href="https://www.buybitcoinbank.com/cryptocurrency/add-dogechain-to-metamask#How%20to%20Bridge%20to%20DogeChain_id=2">
                                        www.buybitcoinbank.com</a></p>
                                    <p>
                                        Link to bridge:<br /> 
                                        <a target="_blank" href="https://bridge.dogechain.dog/bridge">bridge.dogechain.dog/bridge</a></p></Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>2. How to add DogeChain to MetaMask?</Accordion.Header>
                                <Accordion.Body>
                                    <p>RPC details:</p>
                                    <p> Network Name: DogeChain</p>
                                    <p> Network URL: </p>
                                    <p> Chain ID: 2000</p>
                                    <p> Currency Symbol: wDOGE</p>
                                    <p> Block Explorer URL: <a target="_blank" href="https://explorer.dogechain.dog">explorer.dogechain.dog</a></p>
                                    <p> Link to Explanation: <a target="_blank" href="https://www.buybitcoinbank.com/cryptocurrency/add-
                                        dogechain-to-metamask#How%20to%20Add%20DogeChain%20to
                                        %20Metamask_id=0">www.buybitcoinbank.com</a></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>3. What is DogeSwap?</Accordion.Header>
                                <Accordion.Body>
                                    <p>DogeSwap is a decentralized exchange (DEX) where you will be able to trade $DPAD
                                        upon the completion of our token sale.</p>
                                    <p>Link to DogeSwap: <a target="_blank" href="https://www.doge-swap.com/">www.doge-swap.com</a></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>4. What is $WDOGE?</Accordion.Header>
                                <Accordion.Body>
                                    <p>$WDOGE is wrapped $DOGE. $WDOGE allows you to invest and trade in blockchain
                                        applications on the DogeChain network.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>5. What is going to the moon?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Quite simply it's where $DPAD belongs and we'll see you there!</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>6. How many wallets can i use to participate in the presale? </Accordion.Header>
                                <Accordion.Body>
                                    <p>There is no reasonable way to restrict people from using multiple wallets during the
                                        Presale. Therefore, there is no limit to how many wallets you can use.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>7. I represent a project launching on Dogechain. Where can I find out more about
                                    launching my project on DogePad? </Accordion.Header>
                                <Accordion.Body>
                                    <p>You can fill out the contact form found here, or you can send us an email at email at info@dogepad.dog</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Row>
            </Container>
        </div>
    </>)
}
export default FaqPage;