import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Footer from '../component/Layout/Footer'
import Header from '../component/Layout/Header'
import AboutPage from '../pages/AboutPage'
import AllCampaigns from '../pages/AllCampaigns'
import ComingSoon from '../pages/ComingSoon'
import ContactUS from '../pages/ContactUS'
import CreateCampaign from '../pages/CreateCampaign'
import HomePage from '../pages/HomePage'
import PoolPage from '../pages/PoolPage'
import FaqPage from '../pages/FaqPage'

const MainRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/all-campaigns" element={<AllCampaigns />} />
        <Route path="/create-campaign" element={<CreateCampaign />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/contact-us" element={<ContactUS />} />
        <Route path="/pool" element={<PoolPage />} />
        <Route path="/faq" element={<FaqPage />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default MainRoutes;
