import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Tab,
    Tabs,
    Accordion,
    Modal,
    Form,
    Nav,
} from 'react-bootstrap'
import AOS from 'aos';
import emailjs from '@emailjs/browser';
// Importing toastify module
import { toast } from 'react-toastify';
toast.configure()

const ContactUS = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    (function () {
        emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
    })();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            {
                email: email,
                telegram_id: name,
                description: description,
            }
        ).then((result) => {
            console.log(result.text);
            e.target.reset();
            toast.success("Email sent successfully!", { position: toast.POSITION.TOP_RIGHT });
        }, (error) => {
            console.log(error.text);
            toast.error("Something wrong!", { position: toast.POSITION.TOP_RIGHT });
        });
    };
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="contact_us">
            <Container>
                <div className="text-center">
                    <h1>Contact Us</h1>
                    <p className="font_silkscreen">WE'RE HERE TO HELP</p>
                </div>

                <div className="pinkbox_round">
                    <h3 className="text-center">TELL US WHAT YOU NEED IN THE DESCRIPTION BOX BELOW</h3>
                    <form id="test" name="nForm" className="test"
                        onSubmit={sendEmail}>
                        <input type="text" id="honeypot" hidden />

                        <Row>
                            <Col md={6}>
                                <div className="form_group">
                                    <label>Email*</label>
                                    <Form.Control id="email" name="email" type="text" className="form_control" onChange={e => setEmail(e.target.value)} required/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form_group">
                                    <label>Telegram ID (Optional)</label>
                                    <Form.Control id="name" name="telegram_id" type="text" className="form_control" onChange={e => setName(e.target.value)} />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form_group">
                                    <label>Description</label>
                                    <textarea type="text" id="description" name="description" className="form_control" onChange={e => setDescription(e.target.value)}></textarea>
                                </div>
                            </Col>
                        </Row>

                        <div className="btn_wrap text-center">
                            <Button type="submit" className="btn_black">SEND REQUEST</Button>
                        </div>
                    </form>
                </div>
            </Container>
        </div>

    </>)
}
export default ContactUS;