import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AOS from 'aos';
const AboutPage = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        AboutPage
    </>)
}
export default AboutPage;