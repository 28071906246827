import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Collapse,
    Tab,
    Tabs,
    Accordion,
    Modal,
    Form,
    Nav,
} from 'react-bootstrap'

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import AOS from 'aos';
const CreateCampaign = () => {

    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="create_campaign">
            <Container>
                <Row>
                    <div className="col-lg-12">
                        <div className="breadcrumb">
                            <a href="">Dashboard</a>
                            <a href="" className="active">Create</a>
                        </div>
                        <div className="text-center">
                            <h1>CREATE A CAMPAIGN</h1>
                            <p className="font_silkscreen">FILL IN THE DETAILS TO GET LISTED</p>
                        </div>
                        <Tab.Container defaultActiveKey="first">
                            <div className="tab_title">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <span>1</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <span>2</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <span>3</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="tab_content">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="create_campaign_box pinkbox_round">
                                            <h3>STEP 1: TOKEN DETAILS</h3>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Campaign Address</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Soft Cap</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Hard Cap</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Min. Doge Per Wallet</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Max. Doge Per Wallet</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Token Amount (No. of tokens for pre sale)</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button className="btn_black">NEXT STEP</button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div className="create_campaign_box pinkbox_round">
                                            <h3>STEP 2: LAUNCH SETTINGS</h3>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Percentage for locking liquidity to Pancakeswap (%)</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Tokens Per Doge (Pancakeswap)</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Liquidity Lock Duration (in seconds)</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Start Date</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>End Date</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Start Time</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>End Time</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="token_data_card">
                                                <div className="inner_card">
                                                    <p>Total Tokens for Pre sale</p>
                                                    <h3>179.59 DogePad</h3>
                                                </div>
                                                <div className="inner_card">
                                                    <p>Platform Fees</p>
                                                    <h3>$15.015 (1%)</h3>
                                                </div>
                                                <div className="inner_card">
                                                    <p>Tokens to be locked on pancake</p>
                                                    <h3>0.07%</h3>
                                                </div>
                                            </div>
                                            <div className="btn_wrap text-center">
                                                <button className="btn_white">Back</button>
                                                <button className="btn_black">NEXT STEP</button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="create_campaign_box pinkbox_round">
                                            <h3>STEP 3: PRoject Information</h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Project Name</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Token Name</label>
                                                        <Form.Control type="text" className="form_control" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Brief introduction for the project</label>
                                                        <textarea type="text" className="form_control"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form_group">
                                                        <label>Token Icon/Logo</label>
                                                        <div className="upload_logo_wrap">
                                                            <label htmlFor="input-file1">
                                                                <img src="/assets/images/camera-fill.svg" />
                                                                <p>Drag and Drop THe image</p>
                                                            </label>
                                                            <Form.Control id="input-file1" className="big" type="file" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Website</label>
                                                        <Form.Control type="text" className="form_control" placeholder="https://example.com" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Telegram</label>
                                                        <Form.Control type="text" className="form_control" placeholder="https://t.me/example" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Discord Channel (Optional)</label>
                                                        <Form.Control type="text" className="form_control" placeholder="https://discord.gg/example" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form_group">
                                                        <label>Twitter (Optional)</label>
                                                        <Form.Control type="text" className="form_control" placeholder="https://twitter.com/example" />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="btn_wrap text-center">
                                                <button className="btn_white">Back</button>
                                                <button className="btn_black">NEXT STEP</button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Row>
            </Container>
        </div>

    </>)
}
export default CreateCampaign;