import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import dogFaceRound from '../assets/images/dog-face-round.svg';

import AOS from 'aos';
const AllCampaigns = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (<>
        <div className="all_campaigns">
            <div className="container hero_banner">
                <div className="row">
                    <div className="col-lg-5 leftside">
                        <h1>ACTIVE CAMPAIGN</h1>
                        <p>16 TOtal Campaign</p>
                    </div>
                    <div className="col-lg-7">
                        <div className="active_campaign">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>PANCAKESWAP RATE</p>
                                        <h3>15 Stotch/per DOGE</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Min. Required for Early Access</p>
                                        <h3>5000 DogePad</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Presale Rate</p>
                                        <h3>18 Stotch/Per DOGE</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex align-items-end">
                                    <button className="btn_white mt-5">BUY&nbsp;DOGEPAD</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container other_campaign_wrap">
                <div className="row">
                    <div className="col-lg-12 custom_tab">
                        <button className="btn_border active">All</button>
                        <button className="btn_border">Ongoing</button>
                        <button className="btn_border">Upcoming</button>
                        <button className="btn_border">EARLY BIRDs</button>
                        <button className="btn_border">Ended</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="other_campaign">
                            <div className="row row1">
                                <div className="col-lg-9">
                                    <div className="leftside">
                                        <h3 className="mb-3">DOGEPAD</h3>
                                        <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                                        <button className="btn_custom orange">UPCOMING</button>
                                    </div>
                                </div>
                                <div className="col-lg-3 mo_cntr_mt">
                                    <img src={dogFaceRound} alt="" className="img-fluid" />
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="other_campaign">
                            <div className="row row1">
                                <div className="col-lg-9">
                                    <div className="leftside">
                                        <h3 className="mb-3">Microsoft Corporation</h3>
                                        <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                                        <button className="btn_custom pink">EARLY BIRDs         </button>
                                    </div>
                                </div>
                                <div className="col-lg-3 mo_cntr_mt">
                                    <img src={dogFaceRound} alt="" className="img-fluid" />
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="other_campaign">
                            <div className="row row1">
                                <div className="col-lg-9">
                                    <div className="leftside">
                                        <h3 className="mb-3">Domino's PIZZA</h3>
                                        <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                                        <button className="btn_custom skyblue">Ongoing</button>
                                    </div>
                                </div>
                                <div className="col-lg-3 mo_cntr_mt">
                                    <img src={dogFaceRound} alt="" className="img-fluid" />
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="other_campaign">
                            <div className="row row1">
                                <div className="col-lg-9">
                                    <div className="leftside">
                                        <h3 className="mb-3">Instagram</h3>
                                        <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                                        <button className="btn_custom green">SUCCESSFUL</button>
                                    </div>
                                </div>
                                <div className="col-lg-3 mo_cntr_mt">
                                    <img src={dogFaceRound} alt="" className="img-fluid" />
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="other_campaign">
                            <div className="row row1">
                                <div className="col-lg-9">
                                    <div className="leftside">
                                        <h3 className="mb-3">Apple</h3>
                                        <p>Do you have Doge in Robinhood? Don’t worry, Dogechain is reserved for you when Robinhood</p>
                                        <button className="btn_custom red">Failed</button>
                                    </div>
                                </div>
                                <div className="col-lg-3 mo_cntr_mt">
                                    <img src={dogFaceRound} alt="" className="img-fluid" />
                                </div>
                                <hr />
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="inner_card">
                                        <p>Starts in</p>
                                        <h3>3d 12h 9m</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>)
}
export default AllCampaigns;